import { dataProvider } from "@/api/dataProvider.js";
const features = {
    state: () => ({
        features: []
    }),
    mutations: {
        userFeatures(state, features) {
            
            state.features = features.map(feature => feature.FeatureName);
        }
    },
    actions: {
        async getAllFeaturesAction({ commit }) {
            await dataProvider
                .GetAllFeaturesByUser()
                .then((res) => {
                    commit("userFeatures", res.Data);
                })
                .catch((error) => {
                    console.log(error)
                });

            return true;
        }
    },
    modules: {}
}

export default features;