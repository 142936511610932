<template>
  <div :class="updateLocalStorage" class="app-wrapper">
    <HeaderBar v-if="$route.name !== 'LoginView'" />
    <!-- <PageHeading
      v-if="isAuthed == true && $route.name !== 'LoginView'"
      :class="currentRoute"
    /> -->
    <!-- <ThemeOptions /> -->
    <main>
      <router-view />
    </main>
    <FooterBar v-if="$route.name !== 'LoginView'" />
    <create-load-popup></create-load-popup>
  </div>
</template>
<script>
import HeaderBar from "@/components/common/HeaderBar.vue";
import FooterBar from "@/components/common/FooterBar.vue";
import createLoadPopup from "@/components/my-loads/createLoadPopup.vue";
import LDIHub from "./LDIHub";
// import PageHeading from "@/components/common/PageHeading.vue";

export default {
  data() {
    return {
      currentTheme: this.$store.state.login.theme,
    };
  },
  components: {
    HeaderBar,
    FooterBar,
    createLoadPopup,
  },
   created() {

  

  },
  methods: {
    handleScroll() {
      if (navigator.userAgent.indexOf("Firefox") != -1 && window.scrollY < 50) {
        document.querySelector("body").classList.add("scrollIssueFirefox");
      }
      else if (navigator.userAgent.indexOf("Firefox") != -1 && window.scrollY > 50) {
        document.querySelector("body").classList.remove("scrollIssueFirefox");
      }
    }
  },
  computed: {
    updateLocalStorage() {
      document.querySelector("body").className = this.$store.state.login.theme;
      return this.$store.state.login.theme;
    },
    isAuthed: function () {
      return this.$store.state.login.authed == true ? true : false;
    },
    currentRoute() {
      return this.$route.name;
    },
  },
 async mounted() {
    console.log("In mounted");
    LDIHub.start();
    LDIHub.client
      .invoke("SendMessage", "LDI", "Chat started...")
      .then(() => console.log("SendMessage App-Method connected"))
      .catch((err) => console.log("Error while starting method: " + err));
     await this.$store.dispatch('getAllFeaturesAction');
    
  },
};
</script>

<style lang="scss">
.app-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
</style>
