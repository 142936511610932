import { createRouter, createWebHistory } from "vue-router";
import { store } from "@/store/store.js";
import Homepage from "../views/Homepage.vue";

var routes = [
  {
    path: "/home",
    name: "home",
    component: Homepage,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    // route level code-splitting
    // this generates a separate chunk (Dashboard.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Dashboard" */ "../views/Dashboard.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/settings",
    name: "Settings",
    component: () =>
      import(/* webpackChunkName: "Settings" */ "../views/Settings.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
      path: "/my-loads/:loadtype?",
      name: "My Loads",
      props: true,
    component: () =>
      import(/* webpackChunkName: "MyLoads" */ "../views/MyLoads.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
      path: "/carriers-profile/:id",
    name: "Carriers Profile",
    query:{from:'',isTeams:false,driverCount:0},
    component: () =>
      import(/* webpackChunkName: "MyLoads" */ "../views/CarriersProfile.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
      path: "/book-edit/:id?/:editFlag?",
      name: "Book Edit",
      props: true,
    component: () =>
      import(/* webpackChunkName: "MyLoads" */ "../views/BookEdit.vue"),
    meta: {
      requiresAuth: true,
    },
  },  
  {
    path: "/ltl/:id?",
    name: "Ltl",
    props: true,
   component: () =>
    import(/* webpackChunkName: "MyLoads" */ "../views/LtlLoadView.vue"),
   meta: {
    requiresAuth: true,
   },
  },
  {
    path: "/edit-load/:isNetwork",
    name: "Edit Load",
    component: () =>
      import(/* webpackChunkName: "MyLoads" */ "../views/EditLoad.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/report-viewer",
    name: "Report Viewer",
    component: () =>
      import(/* webpackChunkName: "MyLoads" */ "../components/report-viewer.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/",
    name: "LoginView",
    // route level code-splitting
    // this generates a separate chunk (LoginView.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "LoginView" */ "../views/LoginView.vue"),
  },
  {
    path: "/?l=so",
    name: "SignOut",
    component: () => import("@/views/LoginView.vue"),
    meta: { requiresAuth: false },
  },
  {
    path: "/error",
    name: "Error",
    component: () => import("../views/Error.vue"),
    meta: { requiresAuth: false },
  },
  {
    path: "/customer-profile/:id?",
    name: "Customer Profile - Index",
    component: () => import("../views/CustomerProfile/Index.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/intermodal/:id?",
    name: "Intermodal",
    props: true,
    component: () =>
      import(/* webpackChunkName: "MyLoads" */ "../views/IntermodalView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/EDI",
    children: [{
      path: '',
      redirect: () => '/'   
    }, {
      path: '204',
      name: 'EDI 204',
      component: () => import(/* webpackChunkName: "EDI" */ "@/views/EDI/204.vue"),
      beforeEnter: (to, from, next) => {
        document.title = 'EDI - Accept 204';
        next();
      }
    }],
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/search",
    name: "Search - Index",
    component: () => import(/* webpackChunkName: "Search" */ "@/views/Search/Index.vue"),
    beforeEnter: (to, from, next) => {
      document.title = 'Search';
      next();
    },
    meta: {
      requiresAuth: true
    },
  },
  {
    path: "/DormantCustomerManager",
    name: "Dormant Customer Manager",
    props: true,
    component: () =>
      import( "../views/DormantCustomer/DormantCustomerManagerView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
];

// Modify certain routes in production
if (process.env.NODE_ENV === 'production') {
    let modifiedRoutes = [
      {
        path: "/DormantCustomerManager",
        name: "Dormant Customer Manager",
        props: true,
        component: () =>
          import(/* webpackChunkName: "MyLoads" */ "../views/ComingSoon.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/intermodal/:id?",
        name: "Intermodal",
        props: true,
        component: () =>
            import(/* webpackChunkName: "MyLoads" */ "../views/ComingSoon.vue"),
        meta: {
            requiresAuth: true
        }
      },
      {
        path: "/search",
        name: 'Search - Index',
        component: () => import(/* webpackChunkName: "MyLoads" */ "../views/ComingSoon.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/customer-profile/:id?",
        name: "Customer Profile - Index",
        component: () => import(/* webpackChunkName: "MyLoads" */ "../views/ComingSoon.vue"),
        meta: {
          requiresAuth: true,
        },
      },
    ];
    
    routes = routes.filter(x => !modifiedRoutes.map(r => r.name).includes(x.name));

    routes = [
      ...routes,
      ...modifiedRoutes
    ];
} 

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to) => {
  var href = to.href;
  var path = to.path;

  // If our global state is not authed
  if (
    to.meta.requiresAuth == true &&
    store.state.login.authed === false &&
    path != "/"
  ) {
    store.state.login.stateHref = href;
    return {
      path: "/",
    };
  }
});

export default router;
